/* eslint-disable max-len */
import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { coinLibrary } from '../../models/common/coinLibrary';
import { QrContractComponent } from '../qr-contract/qr-contract.component';
import { LoginComponent } from '../login/login.component';
import { ModalProperties } from '../../constants/set-modal-properties';
import { Web3Service } from 'src/app/services/web3.service';
import { SubscriptionModalComponent } from '../../header/subscription-modal/subscription-modal.component';
import { SubscriptionService } from '../../services/subscription.service';
import { AppStoreService } from '../../services/app-store.service';
import { Router } from '@angular/router';
import { UsdxWarningModalComponent } from '../usdx-warning-modal/usdx-warning-modal.component';
import { StayOnDeprecatedTokenService } from 'src/app/services/stay-on-deprecated-token.service';

@Component({
  selector: 'app-empower-and-qr-button',
  templateUrl: './empower-and-qr-button.component.html',
  styleUrls: ['./empower-and-qr-button.component.scss'],
})
export class EmpowerAndQrButtonComponent implements OnInit {
  @Input() coinAmount: number;
  @Input() availableAmount: number;
  @Input() selectedCoin: any;
  @Input() showQR = true;
  // need a way to obtain subscription limit amount
  subscriptionLimitAmount = 500;

  constructor(
    public authService: AuthService,
    private dialog: MatDialog,
    private appStore: AppStoreService,
    private web3Service: Web3Service,
    public subscriptionService: SubscriptionService,
    private router: Router,
    public sodt: StayOnDeprecatedTokenService
  ) {}

  ngOnInit(): void {
    ModalProperties.setBaseParams();
  }

  subscriptionAmountMax() {
    this.subscriptionLimitAmount = this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax;
    return this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax;
  }


  getCoinSymbol() {
    if (this.selectedCoin) {
      return coinLibrary[this.selectedCoin].symbol;
    } else {
      return '';
    }
  }

  getConversion() {
    if (this.selectedCoin) {
      return (this.subscriptionLimitAmount * coinLibrary[this.selectedCoin].usdxConversion || 0);
    } else {
      return 0;
    }
  }

  onLogIn() {
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE

    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];

    this.dialog.open(LoginComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  openQrContract() {
    this.authService.pingUserSession().subscribe();
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE

    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    ModalProperties.properties.data = {
      // this will need to be updated if they want to pass different coin
      data: this.coinAmount > this.subscriptionLimitAmount ? null : {totalSum: this.coinAmount, contractAddress: this.appStore.selectedAssetValue.sendContractAddress}
    };
    this.dialog.open(QrContractComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  //prob not needed
  // openQr() {
  //   if (ModalProperties.properties.screenWidth > 750) {
  //     ModalProperties.properties.width = '750px';
  //     ModalProperties.properties.maxWidth = '90vw';
  //   }
  //   ModalProperties.properties.panelClass = 'modal-background';
  //   this.dialog.open(QrContractComponent, ModalProperties.properties);
  //   ModalProperties.resetProperties();
  // }

  async empowerMetaMask() {
    this.sodt.stayOnDeprecatedToken = false;
    this.authService.pingUserSession().subscribe();
    if (!this.web3Service.checkMetaMask()) {
      ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
      ModalProperties.properties.data = {
        // this will need to be updated if they want to pass different coin
        data: {totalSum: this.coinAmount, contractAddress: this.appStore.selectedAssetValue.sendContractAddress}
      };
      this.dialog.open(QrContractComponent, ModalProperties.properties);
      ModalProperties.resetProperties();
      return;
    }
    //56 is BSC
    //1 is ETH
    if(window.innerWidth > 992) {
      if (this.availableAmount >= this.coinAmount) {
        const chainId = await this.web3Service.getChainId();
        if (chainId !== 56) {
          await this.web3Service.networkRequest('bscNetwork');
          const chainIdTwo = await this.web3Service.getChainId();
          if (chainIdTwo === 56) {
            this.web3Service.sendBep20(this.appStore.selectedAssetValue.sendContractAddress, this.coinAmount);
          }
        } else {
          await this.web3Service.sendBep20(this.appStore.selectedAssetValue.sendContractAddress, this.coinAmount);
        }
      }
    } else {
      ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
      ModalProperties.properties.data = {
        // this will need to be updated if they want to pass different coin
        data: {totalSum: this.coinAmount, contractAddress: this.appStore.selectedAssetValue.sendContractAddress}
      };
      this.dialog.open(QrContractComponent, ModalProperties.properties);
      ModalProperties.resetProperties();
    }
  }
  openQr() {
    this.authService.pingUserSession().subscribe();
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    }
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    this.dialog.open(QrContractComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  openSubscriptionModal() {
    this.authService.pingUserSession().subscribe();
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else {
      //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    }
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    this.dialog.open(SubscriptionModalComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  checkIfOnUSDx00() {
    const location = this.router.url.split('/')[1];
    const currentToken = this.router.url.split('/')[2].split('_')[0];
    // return (location === 'dashboard' && currentToken === 'USDX') || false;
    if (location === 'dashboard' && currentToken === 'USDT' && !this.sodt.stayOnDeprecatedToken) {
      this.openWarningModal();
    } else {
      this.empowerMetaMask();
    }
  }

  openWarningModal() {
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else {
      //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    }
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    this.dialog.open(UsdxWarningModalComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

}
