import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { CirculationDetailsComponent } from '../circulation-details/circulation-details.component';
import { trigger, transition, style, animate, keyframes } from '@angular/animations';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { CirculationHistoryItem } from 'src/app/models/common/circulation-history-models/circulation-history-item';
import { Subscription } from 'rxjs';
import { AppStoreService } from '../../../services/app-store.service';

@Component({
  selector: 'app-card-list',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss'],
  animations: [
    trigger('disableAni', [
      transition(':enter', [])
    ]),
    trigger('enterLeave', [
      transition(':enter', [
        style({ opacity: 0.5, background: 'transparent' }),
        animate('1s ease', keyframes([
          style({ opacity: 1, background: 'transparent', offset: 0 }),
          style({ opacity: 1, background: '{{color}}', offset: 0.5 }),
          style({ opacity: 1, background: 'transparent', offset: 1 })
        ]))
      ], { params: { color: 'rgba(255, 196, 9, 0.2)' } })
    ])
  ]
})
export class CardListComponent implements OnInit, OnDestroy {
  @Input() list: CirculationHistoryItem[];
  @Input() personal = false;
  @Input() type?;
  toggleModal = false;
  detail;


  subscription: Subscription;

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    public appStore: AppStoreService,
    ) { }

  ngOnInit() {
    ModalProperties.setBaseParams();
  }

  trackTXID(index, transaction) {
    return transaction ? transaction.tx_id : undefined;
  }

  openModal(item) {
    this.detail = item;
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen', 'no-x-scroll'];
    ModalProperties.properties.data = {
      data: this.detail,
      isPersonal: true
    };
    this.dialog.open(CirculationDetailsComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
