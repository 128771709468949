/* eslint-disable @typescript-eslint/prefer-for-of */
import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { createChart } from 'lightweight-charts';
import { Subscription } from 'rxjs';
import { CoinListService } from '../../services/coin-list.service';
import { AppStoreService } from '../../services/app-store.service';
import { switchMap } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lightweight-chart',
  templateUrl: './lightweight-chart.component.html',
  styleUrls: ['./lightweight-chart.component.scss']
})
export class LightweightChartComponent implements OnInit, OnDestroy {

  @ViewChild('chartContainer') chartContainer!: ElementRef;

  @Input() isLoading = true;

  chart: any;
  graph: any;
  data: any;
  clicked = false;
  subscription: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;

  constructor(
    private coinListService: CoinListService,
    public appStore: AppStoreService,
    private router: Router,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.assetChangeSubscription();

    this.subscription3 = this.appStore.minimizedMenu.subscribe((res: any) => {
      setTimeout(() => {
        this.onResize();
      }, 0);
    });
  }

  onGoBack() {
    this.chart.timeScale().scrollToRealTime();
  }

  getButtonStatus() {
    return this.chart?.timeScale().scrollPosition() < 0 ? 'block' : 'none';
  }

  assetChangeSubscription() {
    // eslint-disable-next-line max-len
    this.subscription = this.appStore.selectedAsset.pipe(switchMap((id: string) => this.coinListService.getCandleData(id))).subscribe((res: any) => {
      this.isLoading = true;
      this.data = res;
      for(let i = 0; i < res.length; i++) {
        res[i].open = 1;
        res[i].close = 1;
        res[i].high = 1;
        res[i].low = 1;
      }
      this.initChart();
    });

    setTimeout(() => {
      if(!this.chart) {
        this.appStore.setSelectedAsset(this.router.url.split('/')[2].split('_')[0].toLowerCase());
      }
    }, 1000);
  }

  initChart() {
    this.removeChart();
    this.chart = createChart(
      this.chartContainer.nativeElement,
      {
        width: this.chartContainer.nativeElement.offsetWidth,
        height: this.chartContainer.nativeElement.offsetHeight
      }
    );
    this.renderChart();
  }

  removeChart() {
    if(this.chart) {
      this.chart.remove();
    }
  }

  renderChart() {
    const chartOptions = {
      chart: {
        rightPriceScale: {
          scaleMargins: {
            top: 0.2,
            bottom: 0.2,
          },
          borderVisible: false,
        },
        handleScroll: {
          mouseWheel: true,
          pressedMouseMove: true,
        },
        handleScale: {
          axisPressedMouseMove: true,
          mouseWheel: true,
          pinch: true,
      },
        timeScale: {
          timeVisible: true,
          secondsVisible: false,
          borderVisible: false
        },
        layout: {
          backgroundColor: 'transparent',
          textColor: '#485875',
        },
        grid: {
          vertLines: {
            visible: false,
          },
          horzLines: {
            visible: false,
          },
        },
        watermark: {
          color: 'rgba(0, 0, 0, 0)',
        },
        crosshair: {
          color: '#485875',
          vertLine: {
            color: '#485875',
            width: 1,
            style: 3,
            visible: true,
            labelVisible: true,
          },
          horzLine: {
            color: '#485875',
            visible: true,
            labelVisible: true
          },
        },
      },
      series: {
        priceLineVisible: true,
        lastValueVisible: true,
        priceFormat: {
          type: 'price',
          precision: (this.data[0]?.close < 0.0001) ? 7 : 2,
        }
      }
    };
    this.chart.applyOptions(chartOptions.chart);
    // this.chart.unsubscribeCrosshairMove(this.areaCrosshairTooltip);
    this.graph = this.chart.addCandlestickSeries();
    this.graph.applyOptions(chartOptions.series);
    this.graph.setData(this.data);
    this.isLoading = false;
  }

  onResize() {
    if(this.chart) {
      this.chart.resize(this.chartContainer.nativeElement.offsetWidth, this.chartContainer.nativeElement.offsetHeight);
    }
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription3?.unsubscribe();
  }
}
