/* eslint-disable max-len */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { QrContractComponent } from '../../common/qr-contract/qr-contract.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { CoinListService } from 'src/app/services/coin-list.service';
import { Web3Service } from '../../services/web3.service';
import { NumberValueAccessor } from '@angular/forms';
import { CountdownTimerService } from 'src/app/services/countdown-timer.service';
import { OrdersRemainingService } from 'src/app/services/orders-remaining.service';
import { WalletService } from '../../services/wallet.service';
import { SubscriptionService } from '../../services/subscription.service';
import { BuySubscriptionCreditsComponent } from '../../common/buy-subscription-credits/buy-subscription-credits.component';
import { ToastService } from '../../services/toast.service';
import { ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { ScrollService } from 'src/app/services/scroll.service';
import { TranslatePipe } from 'src/app/pipes/translate.pipe';

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.scss'],
})
export class SubscriptionModalComponent implements OnInit, OnDestroy {
  coinAmount: number;
  subscriptionAmount;
  subscriptionCredits;
  totalSum;
  availableAmount;
  maxAmount = 1000;
  availableToTradeAssets: any;
  previousAmount;
  processing: boolean;

  textDay = 0;
  textHour = 0;
  textMin = 0;
  textSec = 0;
  gap: number;
  countdownId: NodeJS.Timeout;
  ordersRemaining: number;
  loadingSubscriptionLimit;

  isChecked = true;
  isWaiting = false;
  paid = false;
  autoRenewOption;
  nowOption;
  error: string;

  loading = false;

  show = false;
  currentCoinImage = 'assets/images/coin-logo/USDT.png';
  selected: any =   {
    id: 'usdt',
    name: 'USDT',
    symbol: 'USDT',
    image: 'assets/images/coin-logo/USDT.png',

  };

  list = [
  {
    id: 'sc',
    name: 'subscription-credits',
    symbol: 'SC',
    image: 'assets/images/credits-empass-v2-250px-min.png',
    balance: 'autopayBalance',
    wallet: 'subscriptionWallet',
    service: 'subscriptionService'
  },
  {
    id: 'usdt',
    name: 'USDT',
    symbol: 'USDT',
    image: 'assets/images/coin-logo/USDT.png',
    balance: 'usdt',
    wallet: 'bep20Wallet',
    service: 'walletService'
  },
];
  subNextPymtAuthorized: any;
  nextpaymentAuthorizedOption: number;
  metamaskSubscription: Subscription;
  subscription: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;
  subscription4: Subscription;
  subscription5: Subscription;
  subscription6: Subscription;
  subscription7: Subscription;
  subscription8: Subscription;
  subscription9: Subscription;
  subscription10: Subscription;
  subscription11: Subscription;

  constructor(
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<SubscriptionModalComponent>,
    private coinList: CoinListService,
    private web3Service: Web3Service,
    public toastController: ToastController,
    private countdownTimer: CountdownTimerService,
    private ordersService: OrdersRemainingService,
    public walletService: WalletService,
    public subscriptionService: SubscriptionService,
    private scrollService: ScrollService,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {
    this.isChecked = this.subscriptionService.subscriptionWallet.subscriptionAutorenew;
    this.subscriptionAmount = '';
    if (this.subscriptionService.subscriptionWallet.activeSubscriptionStatus === 'notfound'
      && !this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax) {
    } else {
      this.previousAmount = this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax;
    }
    this.subscription = this.subscriptionService.getSubscriptionInfo()
    .subscribe(()=>{
      if (this.subscriptionService.subscriptionWallet.activeSubscriptionStatus === 'notfound'
      && !this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax) {
      } else {
        this.previousAmount = this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax;
      }
      if (this.subscriptionService.subscriptionWallet.activeSubscriptionStatus !== 'notfound'
      && this.subscriptionService.subscriptionWallet.activeSubscriptionStatus !== 'active'
      && (this.subscriptionService.subscriptionWallet.activeSubscriptionAutorenew
      || this.subscriptionService.subscriptionWallet.activeSubscriptionNextPymtAuthorized)) {
        this.subscription2 = this.subscriptionService.checkSubscriptionStatus().subscribe(()=>{
          this.subscription3 = this.subscriptionService.getSubscriptionInfo().subscribe();
        });
      }
    });
    ModalProperties.setBaseParams();
    // this.availableAmount = this.walletService.bep20Wallet.usdx;
    this.getTime();
    this.setCountdown();
    this.getOrdersRemaining();
    this.metamaskTxSubscription();
  }

  metamaskTxSubscription() {
    this.metamaskSubscription = this.web3Service.$txConfirmed.subscribe((res: any) => {
      this.isWaiting = res;
    });
  }

  onSelect(selected) {
    this.selected = selected;
    this.show = false;
    // this.availableAmount = selected.balance || 0;
    // switch (selected.id) {
    //   case 'sc':
    //     this.availableAmount = this.subscriptionService.subscriptionWallet.autopayBalance;
    //     break;
    //   case 'usdx':
    //     this.availableAmount = this.walletService.bep20Wallet.usdx;
    //     break;
    // }
  }

  incrementDecrement(type) {
    if (type === 'up') {
      this.subscriptionAmount = (this.subscriptionAmount || 0) + 50;
    } else {
      if (!this.subscriptionAmount) {
        this.subscriptionAmount = '';
        return;
      } else if (this.subscriptionAmount < this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax) {
        return;
      }
      this.subscriptionAmount = parseInt(this.subscriptionAmount, 10) - 50;
      if (!this.subscriptionAmount) {
        this.subscriptionAmount = '';
      }
    }
    if (this.subscriptionAmount > this.maxAmount) {
      this.checkAmount();
    }
  }

  async checkAmount() {
    if (this.subscriptionAmount > 1000) {
      return this.subscriptionAmount = 1000;
    } else if (!this.subscriptionAmount) {
      return this.subscriptionAmount = '';
    }
    const rounded = Math.round(this.subscriptionAmount / 50) * 50;
    if(rounded < 50) {
      const toast = await this.toastController.create({
        message: this.translatePipe.transform('please-use-increments-of-50'),
        duration: 3000
      });
      toast.present();
    }
    return this.subscriptionAmount = rounded || '';
  }

  calculateAmount() {
    //this is an upgrade
    if (this.subscriptionService.subscriptionWallet.activeSubscriptionStatus === 'active') {
      if (this.subscriptionAmount > this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax) {
        this.totalSum = this.subscriptionAmount - this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax;
      } else if (this.subscriptionAmount < this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax) {
        this.totalSum = this.subscriptionAmount;
      }
    } else {
      this.totalSum = this.subscriptionAmount;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getTime(): void {
    this.subscription4 = this.countdownTimer.getTime(this.subscriptionService.subscriptionWallet.activeSubscriptionExpireDt).subscribe(t => {
      this.textHour = t.hour;
      this.textMin = t.min;
      this.textSec = t.sec;
      this.gap = t.gap;
      if (this.gap < 1000) {
        if (this.subscriptionService.subscriptionWallet.activeSubscriptionTradeCnt >= this.subscriptionService.subscriptionWallet.activeSubscriptionTradeCntMax ) {
          this.subscription5 = this.subscriptionService.checkSubscriptionStatus().subscribe(()=>{
            this.subscription6 = this.subscriptionService.getSubscriptionInfo().subscribe(() => {
              this.getTime();
            });
          });
        }
        clearInterval(this.countdownId);
      }
    });
  }

  setCountdown(): void {
    this.countdownId = setInterval(() => this.getTime(), 1000);
  }

  getOrdersRemaining(): void {
    this.subscription7 = this.ordersService.getOrdersRemaining().subscribe(orders => this.ordersRemaining = orders);
  }

  checkValue(event) {
    if (event.keyCode === 38 || event.keyCode === 40 || event.code === 'Minus') {
      event.preventDefault();
      return false;
    }

    if (event.keyCode !== 8 &&
      (((!event.target.value.length || event.target.value == 0) && event.key === '0')
      || event.target.value.length===5
      || event.target.value > 1000)) {
      event.preventDefault();
      return false;
    };
  }

  openBuySubscriptionCredits() {
    this.dialogRef.close();
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    this.dialog.open(BuySubscriptionCreditsComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  // ==============IMPORTANT FUNCTINALITY HAPPENING BELOW ==================

  // THIS IS FOR OPENING METAMASK WHEN THE CLICK SUBSCRIBE
  async openMetaMask() {
    //WHETHER OR NOT API #8 WILL BE CALLED WILL HAPPEN WITHIN THE SENDBEP20SUBSCRIPTION web3service.
    const chainId = await this.web3Service.getChainId();
    if (chainId !== 56) {
      await this.web3Service.networkRequest('bscNetwork');
      const chainddTwo = await this.web3Service.getChainId();
      if (chainddTwo === 56) {
        this.web3Service.sendBep20Subscription(this.totalSum, this.subscriptionService.subscriptionWallet.subscriptionAddress,
          'Your subscription TX has been confirmed. Your Subscription is processing', this.autoRenewOption, this.nowOption, this.subscriptionAmount);
        this.subscriptionAmount = '';
      }
    } else {
      this.web3Service.sendBep20Subscription(this.totalSum, this.subscriptionService.subscriptionWallet.subscriptionAddress,
        'Your subscription TX has been confirmed. Your Subscription is processing', this.autoRenewOption, this.nowOption, this.subscriptionAmount);
      this.subscriptionAmount = '';
    }
  }

  // this is for opening the qr contract, same thing as bove if status is expired or exhausted it will call api #8.
  openQrContract() {
    const status = this.subscriptionService.subscriptionWallet.activeSubscriptionStatus;
    if (this.isChecked) {
      this.autoRenewOption = 1;
    } else {
      this.autoRenewOption = 0;
    }
    this.calculateAmount();
    this.confirmSubLimit();
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '750px';
      ModalProperties.properties.maxWidth = '90vw';
    } else { //Modal Max width for full width pop ups on mobile thingy
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    } //end ELSE
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    ModalProperties.properties.data = {
      data : {
        totalSum: this.totalSum,
        subscriptionModal: true,
        selected: this.selected
      }
    };
    this.dialog.open(QrContractComponent, ModalProperties.properties);
    this.subscriptionAmount = '';
    ModalProperties.resetProperties();
  }

  async confirmSubLimit() {
    if (this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax !== this.subscriptionAmount) {
      this.loadingSubscriptionLimit = true;
      await this.updateUser();
    }
  }

  async updateUser() {
    if (this.isChecked) {
      // If user has auto renew turned on
      this.autoRenewOption = 1;
      // this.nextpaymentAuthorizedOption = 0;
    } else {
      // If user has auto renew turned off
      this.autoRenewOption = 0;
      // this.nextpaymentAuthorizedOption = 1;
    }
    const nextpayAuthOption = this.nextpaymentAuthorizedOption;
    const subSource:any = await this.subscriptionService.updateUser(this.subscriptionAmount, this.autoRenewOption, nextpayAuthOption).toPromise();
    this.subscriptionService.subscriptionWallet.subscriptionAmt = subSource.subscr_amt;
    this.subscriptionService.subscriptionWallet.activeSubscriptionAutorenew = subSource.subscr_autorenew;

    // this.subscription8 = this.subscriptionService.updateUser(this.subscriptionAmount, this.autoRenewOption, nextpayAuthOption).subscribe((data: any) => {
    //   this.subscriptionService.subscriptionWallet.subscriptionAmt = data.subscr_amt;
    //   this.subscriptionService.subscriptionWallet.activeSubscriptionAutorenew = data.subscr_autorenew;
    //   this.loadingSubscriptionLimit = false;
    // });
  }

  toggleAutoRenew() {
    this.processing = true;
    if (this.isChecked) {
      // If user has auto renew turned on
      this.autoRenewOption = 1;
    } else {
      // If user has auto renew turned off
      this.autoRenewOption = 0;
    }
    this.subscription9 = this.subscriptionService.toggleAutoRenew(this.autoRenewOption).subscribe((data: any) => {
      this.subscriptionService.subscriptionWallet.subscriptionAmt = data.subscr_amt;
      this.subscriptionService.subscriptionWallet.activeSubscriptionAutorenew = data.subscr_autorenew;
      this.loadingSubscriptionLimit = false;
      this.processing = false;
    }, (err) => {
      this.processing = false;
    });
  }

  async chargeCreditOrCurrency() {
    //THIS IS FOR THE TOGGLE OF AUTORENEW OFF OR ON
    this.loading = true;
    if (this.isChecked) {
      this.autoRenewOption = 1;
    } else {
      this.autoRenewOption = 0;
    }
    this.nowOption = 0;
    this.calculateAmount();
    //Must come after calculate
    await this.confirmSubLimit();
    if (this.subscriptionAmount < this.previousAmount) {
      //downgrade check
      return;
    }
    if (this.totalSum === 0) {
      return;
    }


    if (this.selected.id === 'usdt') {
      this.web3Service.connectMetaMask();
      if (!this.web3Service.checkMetaMask()) {
        this.loading = false;
        return this.openQrContract();
      }
      if (this.subscriptionService.subscriptionWallet.activeSubscriptionStatus === 'active' && this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax < this.subscriptionAmount) {
        this.nowOption = 1;
        this.openMetaMask();
      } else {
        this.openMetaMask();
      }
      this.loading = false;
    } else {
      //for subscription credits

      if (this.subscriptionService.subscriptionWallet.autopayBalance >= this.totalSum) {
        const status = this.subscriptionService.subscriptionWallet.activeSubscriptionStatus;
        const paymentAuthorized = this.subscriptionService.subscriptionWallet.activeSubscriptionNextPymtAuthorized;
        this.subscription10 = this.subscriptionService.addSubscription(this.totalSum, this.autoRenewOption, 1).subscribe((data) => {
          this.subscription11 = this.subscriptionService.getSubscriptionInfo().subscribe(()=>{
            // switch (this.selected.id) {
            //   case 'sc':
            //     this.availableAmount = this.subscriptionService.subscriptionWallet.autopayBalance;
            //     break;
            //   case 'usdx':
            //     this.availableAmount = this.walletService.bep20Wallet.usdx;
            //     break;
            // }
            this.loading = false;
          });
        });
        this.subscriptionAmount = '';
      } else {
        this.error = 'not-enough-credits';
      }

    }
    //Some other function that I have to build with Curt, for other cases?
    setTimeout(() => {
      this.paid = true;
    }, 5000);
  }

  getText() {
    if (this.subscriptionAmount > this.subscriptionService.subscriptionWallet.activeSubscriptionTradeAmtMax) {
      return 'pay';
    } else {
      return 'subscribe';
    }
  }

  setScroll(): void {
    this.scrollService.navigatedFromSubscriptionModal = true;
  }

  ngOnDestroy() {
    clearInterval(this.countdownId);
    this.metamaskSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
    this.subscription4?.unsubscribe();
    this.subscription5?.unsubscribe();
    this.subscription6?.unsubscribe();
    this.subscription7?.unsubscribe();
    this.subscription8?.unsubscribe();
    this.subscription9?.unsubscribe();
    this.subscription10?.unsubscribe();
    this.subscription11?.unsubscribe();
  }

}

