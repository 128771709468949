import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { WalletService } from '../../services/wallet.service';
import { SubscriptionService } from '../../services/subscription.service';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { ConfirmWithdrawalComponent } from '../confirm-withdrawal/confirm-withdrawal.component';
import { Subscription } from 'rxjs';
import { GenericConfirmationModalComponent } from '../generic-confirmation-modal/generic-confirmation-modal.component';
@Component({
  selector: 'app-convert-creidts',
  templateUrl: './convert-creidts.component.html',
  styleUrls: ['./convert-creidts.component.scss'],
})
export class ConvertCreidtsComponent implements OnInit, OnDestroy {

  inviteCredits;
  loading = false;
  success = false;
  error = false;
  incomingData;

  // subscription: Subscription;
  subscription1: Subscription;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ConvertCreidtsComponent>,
    public walletService: WalletService,
    public subscriptionService: SubscriptionService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.incomingData = this.data.data;
    this.inviteCredits = this.incomingData.inviteCredits || null;
  }

  checkValueCredits(event) {
    if (event.keyCode === 38 || event.keyCode === 40 || event.code === 'Minus') {
      event.preventDefault();
      return false;
    }
    if (event.keyCode !== 8 &&
      (((!event.target.value.length || event.target.value == 0) && event.key === '0')


      )) {
      event.preventDefault();
      return false;
    };
  }

  convertCredits(operation: string) {
    if (
      this.loading
      || !this.inviteCredits
      || this.inviteCredits <= 0
      || this.inviteCredits > this.subscriptionService.subscriptionWallet.bonusBalance
    ) {
      return;
    }

    this.closeDialog();
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    ModalProperties.properties.data = {
      operation: (operation === 'convert' ? 'convert' : 'withdraw'),
      inviteCredits: this.inviteCredits,
      title: (operation === 'convert' ? 'confirm-invite-to-empass-conversion' : 'confirm-invite-to-usdx-withdrawal')
    };
    const dialog = this.dialog.open(ConfirmWithdrawalComponent, ModalProperties.properties);
    ModalProperties.resetProperties();

    dialog.afterClosed().subscribe((res: any) => {
      if (res) {
        // this.error = false;
        // this.success = false;
        // this.loading = true;
        // this.subscription = this.walletService.convertInviteEarnings(this.inviteCredits).subscribe((res: any) => {
        //   this.subscription1 = this.subscriptionService.getSubscriptionInfo().subscribe();
        //   this.loading = false;
        //   this.success = true;
        //   this.inviteCredits = null;
        //     setTimeout(() => {
        //       this.success = false;
        //     }, 10000);
        // },
        // (err: any) => {
        //   this.error = true;
        //   this.inviteCredits = null;
        //   this.loading = false;
        //     setTimeout(() => {
        //       this.error = false;
        //     }, 10000);
        // });
      } else {
        return;
      }
    });
  }

  checkForPlusOrMinus(evt: KeyboardEvent): boolean {
    if (evt.keyCode === 109 || evt.keyCode === 189 || evt.keyCode === 107) {
      return false;
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    // this.subscription?.unsubscribe();
    this.subscription1?.unsubscribe();
  }
}
