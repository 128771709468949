import { Component, OnInit, Inject, OnDestroy, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { WalletService } from '../../services/wallet.service';
import { CoinListService } from '../../services/coin-list.service';
import { Web3Service } from '../../services/web3.service';
import { TokenSwapService } from '../../services/token-swap.service';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { QrContractComponent } from '../qr-contract/qr-contract.component';
import { SwitchNetworkComponent } from '../switch-network/switch-network.component';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { of, timer, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { GeneralDescriptionComponent } from '../general-description/general-description.component';
import { tokenSwapContracts } from 'src/app/constants/addresses';
import { MinUsdtService } from 'src/app/services/min-usdt.service';

@Component({
  selector: 'app-token-swap',
  templateUrl: './token-swap.component.html',
  styleUrls: ['./token-swap.component.scss'],
})
export class TokenSwapComponent implements OnInit, OnDestroy {
  //trade variables
  inputAsset = {
    name: '',
    symbol: '',
    image: '',
  };
  outputAsset = {
    name: '',
    symbol: '',
    image: '',
  };

  inputAmount: number;
  outputAmount: number;
  $input = new BehaviorSubject<any>(null);

  //trading lists
  walletList: any;
  availableToTradeAssets: any;

  //balances - THESE DONT HAVE TO BE HERE, THEY CAN BE IN THE WALLET/WEB3 SERVICE
  bscStablecoinBalance: any;
  availableBalance: number;

  //true for completed - false for not completed
  errorProcess = {
    step1: null,
    step2: null,
  };
  onBoardingProcess = {
    step1: false,
    step2: false,
  };
  enoughBnbGas = false;
  enable = false;

  //swap step
  swapStep: 'swap' | 'confirming' | 'complete' = 'swap';
  toggle;
  //subscription
  timeout;
  swapEstimate: any;

  subscription: Subscription;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public walletService: WalletService,
    private coinList: CoinListService,
    public web3Service: Web3Service,
    private dialogRef: MatDialogRef<TokenSwapComponent>,
    private ngZone: NgZone,
    private dialog: MatDialog,
    public tokenswapService: TokenSwapService,
    public minUsdtService: MinUsdtService
  ) {}

  ngOnInit(): void {
    this.web3Service.hasMetaMask = this.web3Service.checkMetaMask();
    this.getBSCStablecoinsBalance();
    this.checkOnBoardingProcess();

    ModalProperties.setBaseParams();

     //input subscription for estimate
     this.subscription = this.$input.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap((amount: any) => {
        this.inputAmount = amount;
        if (amount === 0 || amount === null) {
          this.outputAmount = 0;
          return of(null);
        }
        setTimeout(() => {
          this.enable = true;
        }, 1500);
        return timer(0,3000).pipe(
          switchMap(() => this.tokenswapService.getEstimate(this.inputAsset.symbol, this.outputAsset.symbol, amount))
        );
      })
    ).subscribe((res: any) => {
      // this.outputAmount = res?.data?.swapToAmount;
      this.outputAmount = this.inputAmount * 3100;
    });
  }

  showBalance(balance) {
    this.availableBalance = balance;
  }

  getBSCStablecoinsBalance() {
    //FRANK needs to implement this
    this.bscStablecoinBalance = {
      usdt: 20000,
    };
    return this.bscStablecoinBalance.usdt;
  }

  toggleStep(step) {
    if (this.walletService.wallet.bnb < 0.01) {
      return;
    }
    if (step === 1) {
      this.onBoardingProcess.step1 = false;
      this.toggle = true;
      this.checkOnBoardingProcess();
    } else if (step === 2 && this.onBoardingProcess.step2 === false) {
      this.onBoardingProcess.step1 = true;
      this.toggle = false;
      this.checkOnBoardingProcess();
    }
  }

  async checkOnBoardingProcess() {
    //this function needs to rerun if one of the balance is not available
    //we can use setTimeout or subscribe to the balances from wallet service
    //the balances will need to be some sort of Subjects though

    // this.walletService.bep20Wallet.usdx = await this.web3Service.checkBep20Balance(this.walletService.wallet.ethAddress);
    // if(!(this.walletService.wallet.bnb >= 0 )|| !(this.getBSCStablecoinsBalance() >= 0)) {
    //   this.timeout = setTimeout(() => {
    //     this.checkOnBoardingProcess();
    //     return;
    //   }, 500);
    //   return;
    // }

    //this runs when user doesn't have enough bnb - so they need to swap ETH to BNB
    if (this.walletService.wallet.bnb < 0.01 || this.toggle) {
      this.onBoardingProcess.step1 = false;
      this.errorProcess.step1 = false;
    } else {
      this.enoughBnbGas = true;
      this.onBoardingProcess.step1 = true;
      this.errorProcess.step1 = true;
    }

    //this runs when user has BNB but the stablecoins for empower
    if (this.getBSCStablecoinsBalance() < 1 && this.onBoardingProcess.step1) {
      this.onBoardingProcess.step2 = false;
      this.errorProcess.step2 = false;

      // THIS WILL TAKE THEM TO THE FINAL STEP
    } else if (this.onBoardingProcess.step1) {
      this.onBoardingProcess.step2 = true;
      this.errorProcess.step2 = true;
    }
    this.onBoardingProcess.step1 = true;
    this.onBoardingProcess.step2 = true;
    this.inputAsset = {
      name: 'Ethereum',
      symbol: 'ETH',
      image: 'assets/images/coin-logo/ETH.png',
    };
    this.outputAsset = {
      name: 'USDT',
      symbol: 'USDT',
      image: 'assets/images/coin-logo/USDT.png',
    };
    this.walletList = this.tokenswapService.fullInputSwapWalletList;
    this.availableToTradeAssets =
      this.tokenswapService.ethNetworkOutputWalletList;
  }

  onInputChange(amount?: number) {
    // this is getting the pair rate, no need to self calculate.
    // if (amount === 0 || amount === null) {
    //   this.outputAmount = 0;
    //   this.inputAmount = 0;
    //   return;
    // }
    // this.tokenswapService
    //   .getEstimate(this.inputAsset.symbol, this.outputAsset.symbol, amount)
    //   .subscribe((data: any) => {
    //     this.inputAmount = amount;
    //     this.outputAmount = data.data.swapToAmount;
    // });
  }

  onSelectedCoin(coin) {
    this.outputAmount = 0;
    if (coin.name !== 'Tether') {
      this.inputAmount = 0;
    } else {
      this.inputAmount = 50;
    }
    this.inputAsset = coin;
    if (this.onBoardingProcess.step1 && this.onBoardingProcess.step2) {
      this.getCryptoFullSwap(coin);
    }
    // this.onInputChange(this.inputAmount);
    this.$input.next(this.inputAmount);

  }

  onOutputSelectedCoin(coin) {
    this.outputAsset = {
      name: coin.name,
      symbol: coin.symbol,
      image: coin.image,
    };
    this.onInputChange(this.inputAmount);
  }

  getCryptoFullSwap(coin?) {
    if (coin.symbol === 'USDT') {
      this.inputAsset = {
        name: 'USDT',
        symbol: 'USDT',
        image: 'assets/images/coin-logo/USDT.png',
      };
      this.outputAsset = {
        name: 'Ethereum',
        symbol: 'ETH',
        image: 'assets/images/coin-logo/ETH.png',
      };
      this.availableToTradeAssets =
        this.tokenswapService.bscNetworkOutputWalletList;
    } else {
      if (coin.symbol === 'ETH') {
        this.inputAsset = {
          name: 'Ethereum',
          symbol: 'ETH',
          image: 'assets/images/coin-logo/ETH.png',
        };
      } else {
        this.inputAsset = {
          name: 'Tether',
          symbol: 'USDT',
          image: 'assets/images/coin-logo/USDT.png',
        };
      }
      this.outputAsset = {
        name: 'USDT',
        symbol: 'USDT',
        image: 'assets/images/coin-logo/USDT.png',
      };
      this.availableToTradeAssets =
        this.tokenswapService.ethNetworkOutputWalletList;
    }
    // this.onInputChange(this.inputAmount);
  }

  onSwap(ifQr) {
    const from = this.inputAsset.symbol;
    const to = this.outputAsset.symbol;
    const amount = this.inputAmount;
    const tokenPair = `${from}to${to}`;

    switch (tokenPair) {
      case 'ETHtoBNB':
        this.checkNetwork(
          tokenSwapContracts.ethToBnb,
          amount,
          'eth',
          ifQr,
          from,
          to
        );
        break;
      case 'USDTtoBNB':
        this.checkNetwork(
          tokenSwapContracts.usdtToBnb,
          amount,
          'erc20',
          ifQr,
          from,
          to
        );
        break;
      default:
    }

    // NEED TO FIGURE OUT WAY TO GO TO NEXT STEP

    // this.swapStep = 'confirming';
    // setTimeout(() => {
    //   this.onComplete();
    // }, 2000);
  }

  async checkNetwork(contractAddress, amount, type?, ifQr?, from?, to?) {
    if (!this.web3Service.hasMetaMask || ifQr) {
      this.openQrContract(contractAddress, amount, from, to);
      return;
    }

    if (type === 'eth' || type === 'erc20') {
      const chainId = await this.web3Service.getChainId();
      if (chainId !== 1) {
        await this.web3Service.networkRequest('ethNetwork');
        const chainIdTwo = await this.web3Service.getChainId();
        if (chainIdTwo === 1) {
          if (type !== 'erc20') {
            this.web3Service.sendEth(contractAddress, amount);
          } else {
            this.web3Service.sendErc20(contractAddress, amount);
          }
        }
      } else {
        if (type !== 'erc20') {
          this.web3Service.sendEth(contractAddress, amount);
        } else {
          this.web3Service.sendErc20(contractAddress, amount);
        }
      }
    } else {
      const chainId = await this.web3Service.getChainId();
      if (chainId !== 56) {
        await this.web3Service.networkRequest('bscNetwork');
        const chainIdTwo = await this.web3Service.getChainId();
        if (chainIdTwo === 56) {
          this.web3Service.sendBep20(contractAddress, amount);
        }
      } else {
        this.web3Service.sendBep20(contractAddress, amount);
      }
    }
  }

  openQrContract(contractAddress, amount, from, to) {
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '700px';
      ModalProperties.properties.maxWidth = '90vw';
    } else {
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    }
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    ModalProperties.properties.data = {
      data: { totalSum: amount, contractAddress, tokenSwap: true, from, to },
    };
    this.dialog.open(QrContractComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  openSwitchNetwork() {
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '700px';
      ModalProperties.properties.maxWidth = '90vw';
    } else {
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    }
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    ModalProperties.properties.data = {
      // data: { totalSum: amount, contractAddress, tokenSwap: true, from, to },
    };
    this.dialog.open(SwitchNetworkComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  onComplete() {
    this.swapStep = 'complete';
  }

  onPerformAnotherSwap() {
    this.swapStep = 'swap';
    this.outputAmount = null;
    this.checkOnBoardingProcess();
  }

  onDone() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  getMetaMaskExtension() {
    const language = localStorage.getItem('lang');
    if (language === 'en') {
      window.open('https://empower.io/downloads#pc', '_blank');
    } else {
      window.open('https://empower.io/downloads?lang=zh-hans#pc', '_blank');
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  openDescription() {
    if (ModalProperties.properties.screenWidth > 750) {
      ModalProperties.properties.width = '700px';
      ModalProperties.properties.maxWidth = '90vw';
    } else {
      ModalProperties.properties.width = '100%';
      ModalProperties.properties.maxWidth = '100vw';
    }
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    ModalProperties.properties.data = {
      // data: { totalSum: amount, contractAddress, tokenSwap: true, from, to },
    };
    this.dialog.open(GeneralDescriptionComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }
}
