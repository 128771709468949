import { Component, OnInit, Inject } from '@angular/core';
import { AppStoreService } from '../../services/app-store.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastService } from '../../services/toast.service';
import { WalletService } from '../../services/wallet.service';
import { Router } from '@angular/router';
import { TokenSwapService } from '../../services/token-swap.service';
import { SubscriptionService } from '../../services/subscription.service';
import { AuthService } from '../../services/auth.service';
import { ModalProperties } from '../../constants/set-modal-properties';
import { LoginComponent } from '../login/login.component';
import { tokenSwapContracts } from 'src/app/constants/addresses';

@Component({
  selector: 'app-qr-contract',
  templateUrl: './qr-contract.component.html',
  styleUrls: ['./qr-contract.component.scss']
})
export class QrContractComponent implements OnInit {

  incomingData;
  tokenSwap = false;
  selectedImage;
  chosen;
  accountCredit = false;

  constructor(
    private clipboard: Clipboard,
    private toast: ToastService,
    public appStore: AppStoreService,
    public matDialog: MatDialogRef<QrContractComponent>,
    public walletService: WalletService,
    public auth: AuthService,
    private router: Router,
    public tokenSwapService: TokenSwapService,
    public subscriptionService: SubscriptionService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.incomingData = this.data.data;
    if (this.incomingData?.selected) {
      this.selectedImage = this.incomingData.selected.image;
    }
    this.tokenSwap = this.data.data.tokenSwap;
    if (this.tokenSwap) {
      this.grabImage();
    }
    this.accountCredit = this.incomingData.accountCredit;
  }

  grabImage() {
    switch (this.incomingData.from.toLowerCase()) {
      case 'eth':
        this.selectedImage = 'assets/images/coin-logo/ETH.png';
        break;
      case 'bnb':
        this.selectedImage = 'assets/images/coin-logo/BNB.png';
        break;
      case 'usdt':
        this.selectedImage = 'assets/images/coin-logo/USDT.png';
        break;
    }
  }

  onAuth(type: string) {
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    if(type === 'login') {
      ModalProperties.properties.data = {type: 'login'};
    } else {
      ModalProperties.properties.data = {type: 'register'};
    }
    this.dialog.open(LoginComponent, ModalProperties.properties);
  }

  close() {
    this.matDialog.close();
  }

  copyAddress(event: any) {
    if (!this.incomingData.subscriptionModal && !this.tokenSwap && !this.incomingData.contractAddress) {
      this.clipboard.copy(this.appStore.selectedAssetValue?.coinAddress);
    } else if (this.incomingData.subscriptionModal && !this.tokenSwap) {
      this.clipboard.copy(this.subscriptionService.subscriptionWallet.subscriptionAddress);
    } else {
      this.clipboard.copy(this.incomingData.contractAddress);
    }
  }

  preventKeys(event: any) {
    if([189, 187, 69].includes(event.keyCode)) {
      event.returnValue = false;
      event.preventDefault();
    }
  }

  openEtherScan(from, to) {
    const tokenPair = `${from}to${to}`;

    switch (tokenPair) {
      case 'ETHtoBNB':
        window.open(`https://etherscan.io/address/${tokenSwapContracts.ethToBnb}`, '_blank');
        break;
      case 'USDTtoBNB':
        window.open(`https://etherscan.io/address/${tokenSwapContracts.usdtToBnb}`, '_blank');
        break;
      case 'ETHtoUSDx':
        window.open(`https://etherscan.io/address/${tokenSwapContracts.ethToUsdx}`, '_blank');
        break;
      case 'USDTtoUSDx':
        window.open(`https://etherscan.io/address/${tokenSwapContracts.usdtToUsdx}`, '_blank');
        break;
      case 'USDxtoETH':
        window.open(`https://bscscan.com/address/${tokenSwapContracts.usdxToEth}`, '_blank');
        break;
      case 'USDxtoUSDT':
        window.open(`https://bscscan.com/address/${tokenSwapContracts.usdxToUsdt}`, '_blank');
        break;
      default:
    }
  }

  goToEmpowerMain() {

  }

  navigate() {
    this.router.navigate(['/wallet']);
    this.close();
  }

}
