/* eslint-disable max-len */
import { AppStoreService } from 'src/app/services/app-store.service';
/* eslint-disable @typescript-eslint/naming-convention */
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../common/login/login.component';
import { ModalProperties } from '../constants/set-modal-properties';
import { UserInfoComponent } from '../common/user-info/user-info.component';
import { AnnouncementModalComponent } from '../common/announcement-modal/announcement-modal.component';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TokenSwapComponent } from '../common/token-swap/token-swap.component';
import { RouteService } from '../services/route.service';
import { ScrollService } from '../services/scroll.service';
import { ForgotPasswordComponent } from '../common/forgot-password/forgot-password.component';
import { ToastrService } from 'ngx-toastr';
import { ForTranslateService } from '../services/for-translate.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  show = false;
  activated = false;
  showAnnouncements = false;
  redAnnouncement;
  inside = false;
  announcements = [
    {
      id: 1,
      hasBeenRead: false,
      title: 'What to do for Empower',
      release_time: '1519129853500',
      content:
        'lorem  ipsunsdi isdiosj inwdieufwo9diov oid oidio ioiodiofjd fodsfiod foids foisdjf ioj',
    },
    {
      id: 2,
      hasBeenRead: false,
      title: 'What to do for Empower',
      release_time: '1519129853500',
      content:
        'lorem  ipsunsdi isdiosj inwdieufwo9diov oid oidio ioiodiofjd fodsfiod foids foisdjf ioj',
    },
    {
      id: 3,
      hasBeenRead: false,
      title: 'What to do for Empower',
      release_time: '1519129853500',
      content:
        'lorem  ipsunsdi isdiosj inwdieufwo9diov oid oidio ioiodiofjd fodsfiod foids foisdjf ioj',
    },
    {
      id: 4,
      hasBeenRead: true,
      title: 'What to do for Empower',
      release_time: '1519129853500',
      content:
        'lorem  ipsunsdi isdiosj inwdieufwo9diov oid oidio ioiodiofjd fodsfiod foids foisdjf ioj',
    },
    {
      id: 5,
      hasBeenRead: true,
      title: 'What to do for Empower',
      release_time: '1519129853500',
      content:
        'lorem  ipsunsdi isdiosj inwdieufwo9diov oid oidio ioiodiofjd fodsfiod foids foisdjf ioj',
    },
  ];
  $currentWindowWidth: Observable<any>;

  constructor(
    public authService: AuthService,
    public dialog: MatDialog,
    public router: Router,
    public appStoreService: AppStoreService,
    private routeService: RouteService,
    private scroll: ScrollService,
    private toastr: ToastrService,
    public translateService: ForTranslateService
  ) {}

  ngOnInit(): void {

    ModalProperties.setBaseParams();
    this.redAnnouncement = this.announcements.some(v => !v.hasBeenRead);
    this.screenSizeCheck();
  }

  ngAfterViewInit() {
    window.dispatchEvent(new Event('resize'));
  }

  screenSizeCheck() {
    const $screenSize = fromEvent(window, 'resize')
    .pipe(debounceTime(300), map(() => {
      if(window.innerWidth < 1500) {
        //between 992 and 1500, the menu is always minimized
        this.appStoreService.minimizedMenu.next(true);
      }
      if(window.innerWidth < 992) {
        //under 992, the filter returns to normal
        this.appStoreService.minimizedMenu.next(false);
      }
      return window.innerWidth > 992;
    }));

    this.$currentWindowWidth = $screenSize;
  }

  toggleAnnouncements(event) {
    this.appStoreService.notificationDropdown = !this.appStoreService.notificationDropdown;
    event.stopPropagation();
  }

  accountLogin() {
    if (ModalProperties.properties.screenWidth > 600) {
      ModalProperties.properties.width = '600px';
      ModalProperties.properties.maxWidth = '90vw';
    }
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];

    this.dialog.open(LoginComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  openUserInfo() {
    this.dialog.open(UserInfoComponent, {
      width: '40vw',
      panelClass: 'modal-background',
    });
  }

  minimize() {
    if(window.innerWidth > 1500) {
      if(this.appStoreService.minimizedMenu.value) {
        this.appStoreService.minimizedMenu.next(false);
      } else {
        this.appStoreService.minimizedMenu.next(true);
      }
    }
  }

  scrollToCirculationHistory() {
    this.scroll.scrollToCirculationHistory.next(Math.random());
  }

  openProfilMenu(event) {
    if (!this.activated || !this.appStoreService.settingsDropdown) {
      this.appStoreService.settingsDropdown = true;
      setTimeout(() => {
        this.activated = true;
      }, 100);
    } else {
      this.activated = false;
      this.appStoreService.settingsDropdown = false;
    }
    event.stopPropagation();
  }

  openAnnouncementModal(announcement: any) {
    if (ModalProperties.properties.screenWidth > 600) {
      ModalProperties.properties.width = '600px';
      ModalProperties.properties.maxWidth = '90vw';
    }
    ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
    ModalProperties.properties.data = {
      content: announcement.content,
      title: announcement.title,
      time: announcement.release_time
    };

    this.dialog.open(AnnouncementModalComponent, ModalProperties.properties);
    ModalProperties.resetProperties();
  }

  onSwap() {
    if (!this.authService.authObj.isLoggedIn) {
      ModalProperties.setBaseParams();
      if (ModalProperties.properties.screenWidth > 750) {
        ModalProperties.properties.width = '700px';
        ModalProperties.properties.maxWidth = '90vw';
      } else {
        ModalProperties.properties.width = '100%';
        ModalProperties.properties.maxWidth = '100vw';
      }
      ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
      this.dialog.open(LoginComponent, ModalProperties.properties);
      ModalProperties.resetProperties();
    } else if (!this.authService.authObj.walletConnected) {
      this.router.navigate(['/wallet']);
    } else {
      ModalProperties.setBaseParams();
      if (ModalProperties.properties.screenWidth > 750) {
        ModalProperties.properties.width = '700px';
        ModalProperties.properties.maxWidth = '90vw';
      } else {
        ModalProperties.properties.width = '100%';
        ModalProperties.properties.maxWidth = '100vw';
      }
      ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
      ModalProperties.data = this.appStoreService.selectedAssetValue.symbol;
      const element = this.dialog.open(TokenSwapComponent, ModalProperties.properties);
    }
  }

  markAsRead(notification: any) {
    notification.hasBeenRead = true;
    if (this.announcements.every(v => v.hasBeenRead)) {
      this.redAnnouncement = false;
    }
  }

  mobileGoBack( ){
    if(this.routeService.lastVisited) {
      this.router.navigate([this.routeService.lastVisited]);
    } else {
      this.router.navigate(['home']);
    }
  }
}
