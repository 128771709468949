import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
  @Input() placeholderText;
  @Output() newSearchEvent = new EventEmitter<any>();
  searchInput;
  constructor() {}

  ngOnInit() {}

  searchEmit(event) {
    this.newSearchEvent.emit(event);
  }

}
