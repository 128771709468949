/* eslint-disable no-underscore-dangle */
/* eslint-disable arrow-body-style */
/* eslint-disable max-len */
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, Input, HostListener, Host } from '@angular/core';
import { ProductsService } from '../../services/products.service';
import * as xml2js from 'xml2js';
import { Subscription } from 'rxjs';
import { NewsRss } from 'src/app/models/common/news-rss';
import { ForTranslateService } from 'src/app/services/for-translate.service';
@Component({
  selector: 'app-products-list',
  templateUrl: './products-list.component.html',
  styleUrls: ['./products-list.component.scss']
})
export class ProductsListComponent implements OnInit, OnDestroy {

  @Input() horizontal = false;
  @Input() searchBar = true;
  @Input() text = 'latest-news';
  @Input() hideScrollBar = false;

  @ViewChild('newsCardContainer',) private newsCardContainer!: ElementRef;
  @ViewChild('newsBox') private newsBoxContainer!: ElementRef;
  @ViewChild('newsContainer') private newsContainer!: ElementRef;


  news = [];
  news1 = [];
  autoScrollSubscription: any;
  isScrollingDown = true;
  newsSubscription: any;
  subscription: Subscription;
  subscription1: Subscription;

  constructor(
    private products: ProductsService,
    private translate: ForTranslateService
  ) { }

  // stops autoscroll while user is holding down on their touchscreen
  @HostListener('touchstart')
  start() {
    this.stopAutoScroll();
  }

  // starts autoscroll after user releases hold from their touchscreen
  @HostListener('touchend')
  end() {
    this.autoScroll();
  }

  ngOnInit(): void {
    this.getProducts();
    this.autoScroll();

    this.subscription1 = this.translate._onLanguageChange.subscribe((res: any) => {
      this.news = [];
      this.news1 = [];
      this.getProducts();
    });
  }

  // on small screens, sets the width of news content to whatever the size of the whole container is minus the thumbnail img width
  checkSmScreenBox(): string {
    if (window.innerWidth <= 360) {
      const containerWidth = this.newsBoxContainer.nativeElement.offsetWidth;
      const newWidth = containerWidth - 128; // 128 is the size of the article thumbnail img
      return newWidth + 'px';
    }
    return '200px';
  }

  getProducts() {
    // this.subscription = this.products.getProducts().subscribe(data => {
    //   const text = JSON.parse(data).Items[0].rss;
    //   for (const el of text) {
    //     this.parseString(el);
    //   }
    // });
  }

  parseString(feed: string) {
    const parseString = xml2js.parseString;
    parseString(feed, (err: any, res: NewsRss) => {
      if (res) {
        const items = res.rss.channel[0].item;
        items.forEach((item: any) => {
          if (item.description.length) {
            const obj: any = {};
            obj.source = res.rss.channel[0].link[0];
            obj.description = item.description[0];
            obj.title = item.title[0].split(' ').slice(0, 11).join(' ') + '...';
            obj.url = item.link[0];
            obj.date = item.pubDate[0].split(', ')[1].slice(0, 11);
            if (item.description[0].match(/src="([^"]*)/g)) {
              obj.image = item.description[0].match(/src="([^"]*)/g)[0].replace('src="', '');
            } else if (item['media:thumbnail']) {
              obj.image = item['media:thumbnail'][0].$.url;
            } else {
              obj.image = '';
            }

            //check language for btcpro.net or btcpro.com
            if (item.link[0].includes('btcpro')) {
              if (!this.translate.currentLanguage.includes('zh') && item.link[0].includes('btcpro.com')) {
                this.news.push(obj);
                this.news1.push(obj);
              }
              if (this.translate.currentLanguage.includes('zh') && item.link[0].includes('btcpro.net')) {
                this.news.push(obj);
                this.news1.push(obj);
              }
            } else {
              this.news.push(obj);
              this.news1.push(obj);
            }
          }
        });
      }
    });
  }

  onClick(index: number) {
    window.open(this.news[index].url);
  }

  onSearch(val: string) {
    this.news = this.news1.filter((res: any) => {
      return res.title.toLowerCase().includes(val.toLowerCase());
    });
  }

  autoScroll() {
    if (!this.horizontal) {
      this.autoScrollSubscription = setInterval(() => {
        if (this.newsBoxContainer.nativeElement.scrollTop >= this.newsCardContainer.nativeElement.clientHeight - this.newsBoxContainer.nativeElement.clientHeight) {
          this.isScrollingDown = false;
        } else if (this.newsBoxContainer.nativeElement.scrollTop === 0) {
          this.isScrollingDown = true;
        }

        if (this.isScrollingDown) {
          this.newsBoxContainer.nativeElement.scrollTop += 86;
        } else if (!this.isScrollingDown) {
          this.newsBoxContainer.nativeElement.scrollTop -= 84;
        }
      }, 5000);
    } else {
      this.autoScrollSubscription = setInterval(() => {
        if (this.newsBoxContainer.nativeElement.scrollWidth >= this.newsCardContainer.nativeElement.clientWidth - this.newsBoxContainer.nativeElement.clientWidth) {
          this.isScrollingDown = false;
        } else if (this.newsBoxContainer.nativeElement.scrollWidth === 0) {
          this.isScrollingDown = true;
        }

        if (this.isScrollingDown) {
          // this.newsBoxContainer.nativeElement.scrollLeft -= 328;
          this.newsBoxContainer.nativeElement.scrollLeft -= this.newsContainer.nativeElement.offsetWidth;
        } else if (!this.isScrollingDown) {
          // this.newsBoxContainer.nativeElement.scrollLeft += 328;
          this.newsBoxContainer.nativeElement.scrollLeft += this.newsContainer.nativeElement.offsetWidth;
        }
      }, 5000);
    }
  }

  stopAutoScroll() {
    clearInterval(this.autoScrollSubscription);
  }

  ngOnDestroy() {
    clearInterval(this.autoScrollSubscription);
    this.subscription?.unsubscribe();
    this.subscription1?.unsubscribe();
  }
}
