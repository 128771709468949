/* eslint-disable max-len */
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { fade } from 'src/app/animations/animations';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { AppStoreService } from '../../services/app-store.service';
import { LoginComponent } from '../login/login.component';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { debounceTime } from 'rxjs/operators';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  animations: [
    fade
  ]
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {

  countdown = 0;
  interval: any;
  pwCodeSent = false;
  verificationForm: FormGroup;
  checkingEmail: boolean;
  emailExists: boolean;
  email: string;
  sentEmail: string;
  showPassword = false;
  submitted: boolean;
  error: string;
  processing: boolean;
  verificationFormSubmitted: boolean;
  checked: boolean;
  pwChecked: boolean;
  pwMatchChecked: boolean;
  formSubmitError: boolean;
  userAlreadyLoggedIn: boolean;
  oldPwChecked: boolean;
  // eslint-disable-next-line max-len
  // password needs to have at least one of the following: lowercase and uppercase letter, numeric digit, special character; no spaces allowed
  // pwRegex = /^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!?@#$%^&*()+=`~/\\|\-_<>.,'";:\[\]\{\}])[a-zA-Z0-9!?@#$%^&*()+=`~/\\|\-_<>.,'";:\[\]\{\}]*$/;

  // password can contain any combination of lowercase/uppercase letters, numeric digits, and special characters; no spaces allowed
  pwRegex = /^[a-zA-Z0-9!?@#$%^&*()+=`~/\\|\-_<>.,'";:\[\]\{\}]*$/;

  subcription: Subscription;
  subcription1: Subscription;
  subcription2: Subscription;
  subcription3: Subscription;
  subcription4: Subscription;
  subcription5: Subscription;
  subcription6: Subscription;
  subcription7: Subscription;
  subcription8: Subscription;
  subcription9: Subscription;

  constructor(
    private matDialog: MatDialogRef<ForgotPasswordComponent>,
    private openLogin: MatDialog,
    public router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private appStoreService: AppStoreService
  ) {
    this.verificationForm = fb.group({
      email: [(this.authService.authObj.isLoggedIn ? this.authService.authObj.username : ''), {
        validators: [
          Validators.required,
          Validators.pattern('^[a-zA-Z]{1}[a-zA-Z0-9.\-_]*@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,}$')
        ],
        updateOn: 'change'
      }],
      verificationCode: ['', {
        validators: [
          Validators.required,
          Validators.minLength(6)
        ],
        updateOn: 'blur'
      }],
      oldPw: ['', {
        validators: [
          Validators.minLength(8)
        ],
        updateOn: 'change'
      }],
      newPw: ['', {
        validators: [
          Validators.required,
          Validators.pattern(this.pwRegex),
          Validators.minLength(8)
        ],
        updateOn: 'change'
      }],
      confirmNewPw: ['', {
        validators: [
          Validators.required
        ],
        updateOn: 'change'
      }],
    },
    { validator: [this.compareOldAndNewPw, this.validatePasswords] }
    );
  }

  ngOnInit(): void {
    if (this.verificationForm.controls.email.value) {
      this.email = this.verificationForm.controls.email.value;
      this.userAlreadyLoggedIn = true;
      this.emailExists = true;
    }
    this.subcription = this.verificationForm.valueChanges.subscribe(data => {
      this.email = data.email;
      // this.emailExists = true;
      this.checked = false;
    });
    this.subcription1 = this.verificationForm.controls.email.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.checkEmailExists();
    });
    this.subcription2 = this.verificationForm.controls.confirmNewPw.valueChanges.subscribe(() => {
      this.pwMatchChecked = false;
    });
    this.subcription3 = this.verificationForm.controls.confirmNewPw.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.validatePasswords(this.verificationForm);
      this.pwMatchChecked = true;
    });
    this.subcription4 = this.verificationForm.controls.newPw.valueChanges.subscribe(() => {
      this.pwChecked = false;
      this.pwMatchChecked = false;
      this.oldPwChecked = false;
    });
    this.subcription5 = this.verificationForm.controls.newPw.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      if (this.verificationForm.controls.confirmNewPw.value) {
        this.validatePasswords(this.verificationForm);
      }
      this.compareOldAndNewPw(this.verificationForm);
      this.pwMatchChecked = true;
      this.pwChecked = true;
      this.oldPwChecked = true;
    });
    this.subcription6 = this.verificationForm.controls.oldPw.valueChanges.subscribe(() => {
      this.oldPwChecked = false;
    });
    this.subcription7 = this.verificationForm.controls.oldPw.valueChanges.pipe(debounceTime(500)).subscribe(() => {
      this.compareOldAndNewPw(this.verificationForm);
      this.oldPwChecked = true;
    });

    if (this.router.url === '/settings') {
      this.verificationForm.get('oldPw').addValidators(Validators.required);
    }
  }

  close() {
    this.matDialog.close();
    // this.router.navigate(['/dashboard/USDX2_USDX'], { queryParams: { query: null } });
  }

  checkEmailExists() {
    // eslint-disable-next-line max-len
    if ((this.verificationForm.controls.email.value === '' && this.verificationForm.controls.email.dirty) || this.verificationForm.controls.email.invalid) {
      this.emailExists = false;
      this.checkingEmail = false;
      this.checked = true;
      return;
    };
    this.checkingEmail = true;
    this.subcription6 = this.authService.checkUserExists(this.verificationForm.controls.email.value).subscribe((response: any) => {
      this.checkingEmail = true;
      this.emailExists = response.userExists;
      if (this.emailExists) {
        this.checkingEmail = false;
      } else {
        this.checkingEmail = false;
      }
    });
    this.checked = true;

  }

  sendCode() {
    this.checkingEmail = true;
    this.subcription7 = this.authService.sendEmailVerificationCodeOrPasswordForget(this.email).subscribe((res) => {
      this.pwCodeSent = true;
      this.sentEmail = this.email;
      this.checkingEmail = false;
      if (!this.countdown) {
        this.countdown = 60;
        this.interval = setInterval(() => {
          this.countdown--;
          if (!this.countdown) {
            clearInterval(this.interval);
          }
        }, 1000);
      } else {
        this.checkingEmail = false;
      }
    });
  }

  changePassword() {
    this.verificationFormSubmitted = true;
    if (
      this.verificationForm.controls.newPw.value === this.verificationForm.controls.confirmNewPw.value &&
      this.verificationForm.controls.newPw.valid &&
      this.verificationForm.controls.confirmNewPw.valid
    ) {
      this.processing = true;
      if (this.router.url !== '/settings') {
        this.subcription8 = this.authService.forgotPassword(this.verificationForm.controls.verificationCode.value, this.verificationForm.controls.newPw.value)
          .subscribe((res: any) => {
            this.formSubmitError = false;
            this.processing = false;
            this.matDialog.close();
            if (ModalProperties.properties.screenWidth > 600) {
              ModalProperties.properties.width = '600px';
              ModalProperties.properties.maxWidth = '90vw';
            }
            ModalProperties.properties.maxHeight = '500px';
            ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];

            this.openLogin.open(LoginComponent, ModalProperties.properties);
            ModalProperties.resetProperties();
          }, (err) => {
            this.processing = false;
            this.error = err;
            this.formSubmitError = true;
          });
      } else {
        // eslint-disable-next-line max-len
        this.subcription8 = this.authService.changePassword(this.verificationForm.controls.verificationCode.value, this.verificationForm.controls.oldPw.value, this.verificationForm.controls.newPw.value)
          .subscribe((res: any) => {
            this.formSubmitError = false;
            this.processing = false;
            this.matDialog.close();

            ModalProperties.properties.width = '600px';
            ModalProperties.properties.maxWidth = '90vw';
            ModalProperties.properties.height = '100%';
            ModalProperties.properties.maxHeight = '500px';
            ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
            this.openLogin.open(ChangePasswordComponent, ModalProperties.properties);
            ModalProperties.resetProperties();
          }, (err) => {
            this.processing = false;
            this.error = err;
            this.formSubmitError = true;
          });
      }
    }
  }

  ngOnDestroy() {
    clearInterval(this.interval);
    this.subcription?.unsubscribe();
    this.subcription1?.unsubscribe();
    this.subcription2?.unsubscribe();
    this.subcription3?.unsubscribe();
    this.subcription4?.unsubscribe();
    this.subcription5?.unsubscribe();
    this.subcription6?.unsubscribe();
    this.subcription7?.unsubscribe();
    this.subcription8?.unsubscribe();
  }

  private compareOldAndNewPw(form: FormGroup) {
    const c = form.controls;
    if (c.oldPw.value === c.newPw.value && form.pristine) {
      return;
    }
    return ((c.newPw.value !== c.oldPw.value)) ? delete form.errors?.newPwSameAsOldPw : {...form.errors, newPwSameAsOldPw: true};
  }


  private validatePasswords(form: FormGroup) {
    //if passwords match, return null because there is no error
    return (form.controls.newPw.value === form.controls.confirmNewPw.value) ? delete form.errors?.mismatch : {...form.errors, mismatch: true };
  }

}
