/* eslint-disable max-len */
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { CirculationService } from 'src/app/services/circulation.service';
import { AppStoreService } from '../../services/app-store.service';
import { Observable, Subscription } from 'rxjs';
import { CoinAsset } from '../../models/common/coin-asset';
import { WebsocketService } from 'src/app/services/websocket.service';
import { CirculationHistoryItem } from 'src/app/models/common/circulation-history-models/circulation-history-item';
import { Web3Service } from '../../services/web3.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../services/auth.service';
import { WalletService } from '../../services/wallet.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-circulation-history',
  templateUrl: './circulation-history.component.html',
  styleUrls: ['./circulation-history.component.scss']
})
export class CirculationHistoryComponent implements OnInit, AfterViewInit, OnDestroy {
  psList: CirculationHistoryItem[] = [];
  gsList: CirculationHistoryItem[] = [];

  $selectedAsset: Observable<CoinAsset>;

  historySub: Subscription;
  subscription: Subscription;
  subscription1: Subscription;
  subscription2: Subscription;
  subscription3: Subscription;

  constructor(
    private circulation: CirculationService,
    public appStore: AppStoreService,
    // private websocketService: WebsocketService,
    private web3service: Web3Service,
    private toastr: ToastrService,
    public auth: AuthService,
    private walletService: WalletService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.subscription2 = this.appStore.selectedAsset.subscribe(res => {
    //   if(this.auth.authObj.isLoggedIn) {
    //     this.getHistoryPS();
    //     this.getHistoryGS();
    //   }
    // });

    this.subscription3 = this.auth.loginObservable.subscribe((res: any) => {
      this.getHistoryPS();
      this.getHistoryGS();
    });

    setInterval(()=>{
      this.addPS();
      this.addGS();
    }, 1000)

    // this.historySub = this.websocketService.getMessage('get new data')
    //   .subscribe((result: any) => {
    //     if (result && (result.recordType === 'PS_SENT' ||
    //       result.recordType === 'pymt') && result.data.userAddress === this.walletService.wallet.ethAddress) {
    //       this.addPS(result);
    //       this.toastr.clear(this.web3service.confirmedToast?.toastId);
    //       this.toastr.info(
    //         `<span class="text-bold">Your EMPOWER has been processed successfully!</span></br></br> <i class="icon icon-checkmark tertiary"></i> <span>Keep Empowering!</span>`,
    //         '',
    //         {
    //         timeOut: 10000,
    //         closeButton: true,
    //         easeTime: 150,
    //         progressBar: true
    //       });
    //     }

    //     if (result.recordType === 'circ' && result.data.userAddress === this.walletService.wallet.ethAddress) {
    //       this.getHistoryPS();
    //       this.addGS(result.data);
    //     }
    //   });

    this.$selectedAsset = this.appStore.selectedAssetInfo;
  }

  addPS() {
      // const data = results.data;
      // this.getHistoryPS();
      // if (!this.psList.find(psItem => psItem.tx_id === data.psTxID)) {
        // console.log('data', this.psList)
        // console.log('adding ps', results);
        this.psList.unshift(new CirculationHistoryItem(
          Math.floor(Math.random() * 1001),
          null,
          null,
          null,
          'USDT',
          'Wait Payout',
          'Wait Payout', // not sure what this is
          Date.now().toString(),
          '0x329154bce4d20693bb6dc2d075b4c1114f6240616152516e5776879b4b4d3aed',
          0,
          'PS'
        ));
      // }
  }

  addGS() {
    // if (!this.gsList.find(gs => gs.tx_id === results.psTxID)) {
      // this.getHistoryPS();
      // this.getHistoryGS();
      this.gsList.unshift(new CirculationHistoryItem(
        Math.floor(Math.random() * 1001),
        Math.floor(Math.random() * 1001),
        '0x329154bce4d20693bb6dc2d075b4c1114f6240616152516e5776879b4b4d3aed',
        Date.now().toString(),
        'USDT',
        'complete',
        null,
        Date.now().toString(),
        '0x329154bce4d20693bb6dc2d075b4c1114f6240616152516e5776879b4b4d3aed',
        0,
        'GS'
      ));
    // }
  }

  getHistoryPS() {
    // this.subscription = this.circulation.getCirculationPS(this.appStore.selectedAssetValue.symbol.toUpperCase(), this.appStore.selectedAssetValue.issue)
    //   .subscribe((res: CirculationHistoryItem[]) => {
    //     this.psList = res.splice(0,80);
    //     if(document.getElementById('psList')) {
    //       document.getElementById('pslist').scrollTop = document.getElementById('pslist')?.scrollHeight;
    //     }
    //   });
  }

  getHistoryGS() {
    // this.subscription1 = this.circulation.getCirculationGS(this.appStore.selectedAssetValue.symbol.toUpperCase(), this.appStore.selectedAssetValue.issue)
    //   .subscribe((res: CirculationHistoryItem[]) => {
    //       this.gsList = res.splice(0,80);
    //   });
  }

  scrollToBottom(): void {
    if(document.getElementById('pslist')) {
      document.getElementById('pslist').scrollTop = document.getElementById('pslist')?.scrollHeight;
    }
  }

  ngAfterViewInit() {
    this.scrollToBottom();
  }

  ngOnDestroy(): void {
    this.historySub?.unsubscribe();
    this.subscription?.unsubscribe();
    this.subscription1?.unsubscribe();
    this.subscription2?.unsubscribe();
    this.subscription3?.unsubscribe();
  }
}
