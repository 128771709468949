import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-circulation-details',
  templateUrl: './circulation-details.component.html',
  styleUrls: ['./circulation-details.component.scss'],
})
export class CirculationDetailsComponent implements OnInit {
  itemDetail: any;

  constructor(
    public dialogRef: MatDialogRef<CirculationDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public clipboard: Clipboard
  ) { }

  ngOnInit() {
    this.itemDetail = this.data.data;
    console.log(this.itemDetail)
  }

  clickTxID(txHash) {
    if (txHash !== '...') {
      this.clipboard.copy(txHash);
      window.open('https://bscscan.com/tx/' + txHash, '_blank');
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
