import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private http: HttpClient
  ) { }

  getProducts(): Observable<any> {
    const requestOptions = {
      observe: 'body',
      responseType: 'text'
    };
    return this.http.get<any>('https://7jaqplg6b5.execute-api.ap-east-1.amazonaws.com/prod/rssfeed', requestOptions as any);
  }
}
