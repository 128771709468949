/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment.prod';
import { WebsocketService } from './websocket.service';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Web3Service } from './web3.service';
import { AppStoreService } from './app-store.service';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  subscriptionWallet = {
    autopayBalance: 0,
    bonusBalance: 0,
    subscriptionAmt: 0,
    subscriptionAutorenew: false,
    activeSubscriptionId: null,
    activeSubscriptionExpireDt: null,
    activeSubscriptionTradeAmtMax: null,
    activeSubscriptionTradeCntMax: null,
    activeSubscriptionTradeCnt: null,
    activeSubscriptionAutorenew: null,
    activeSubscriptionStatus: null,
    activeSubscriptionNextPymtAuthorized: null,
    subscriptionAddress: null,
    activeSubscriptionTradeCntMin: null,
    subscriptionAutoTransfer: null,
    submitCount: 0
  };

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private websocketService: WebsocketService,
    private toastr: ToastrService,
    private web3Service: Web3Service
  ) {
    // this.websocketService.getMessage()
    //   .subscribe((response: any) => {
    //     if (response && (response.type === 'subscription' ||
    //       response.type === 'subscr' ||
    //       response.type === 'gift' ||
    //       response.type === 'swap') &&
    //       this.subscriptionWallet.subscriptionAddress.indexOf(response.destWallet) === 0) {
    //       const amt = response.amt.toFixed(2);
    //       this.getSubscriptionInfo().subscribe();
    //       this.toastr.info(
    //         `<span class="text-bold">Your EMPass has been Activated successfully!</span></br></br> <i class="icon icon-checkmark tertiary"></i> <span>EMPass Limit: ${amt}USDx</span>`,
    //         'Subscription Status', {
    //         timeOut: 10000,
    //         closeButton: true,
    //         easeTime: 150,
    //         progressBar: true
    //       });
    //       this.web3Service.$txConfirmed.next(false);
    //       this.toastr.clear(this.web3Service.confirmedToast.toastId);
    //     }
    //   });
  }

  addSubscription(subscriptionAmount, autopayOption, nowOption?) {
    const body: any = {
      sessIDstr: this.authService.authObj.sessIDstr,
      now: 1
    };
    if (nowOption) {
      body.now = nowOption;
    }
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.post(environment.userSubscriptionEndpoint, JSON.stringify(body), config);
  }

  updateUser(subscriptionAmount, autopayOption, nextPaymentAuthorizedOption?) {
    this.subscriptionWallet.subscriptionAmt = subscriptionAmount;
    const body = {
      sessIDstr: this.authService.authObj.sessIDstr,
      dfltSubscriptionAmt: subscriptionAmount,
      autopay: autopayOption,
      // nextPaymentAuthorized: nextPaymentAuthorizedOption
    };

    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.put(environment.registerEndpoint, JSON.stringify(body), config);
  }

  toggleAutoRenew(autopayOption, nextPaymentAuthorizedOption?) {
    const body = {
      sessIDstr: this.authService.authObj.sessIDstr,
      autopay: autopayOption,
      // nextPaymentAuthorized: nextPaymentAuthorizedOption
    };
    const config = { headers: new HttpHeaders().set('Content-Type', 'application/json') };
    return this.http.put(environment.registerEndpoint, JSON.stringify(body), config);
  }

  getSubscriptionInfo() {
    const url = `${environment.userSubscriptionEndpoint}?sessIDstr=${this.authService.authObj.sessIDstr}`;
    return this.http.get(url).pipe(map((data: any) => {
      this.subscriptionWallet.autopayBalance = data.autopay_balance;
      this.subscriptionWallet.bonusBalance = data.bonus_balance;
      this.subscriptionWallet.subscriptionAmt = data.subscription_amt;
      this.subscriptionWallet.subscriptionAutorenew = data.subscription_autorenew;
      this.subscriptionWallet.activeSubscriptionId = data.active_subscription_id;
      this.subscriptionWallet.activeSubscriptionExpireDt = data.active_subscription_expire_dt;
      this.subscriptionWallet.activeSubscriptionTradeAmtMax = data.active_subscription_trade_amt_max;
      this.subscriptionWallet.activeSubscriptionTradeCntMax = data.active_subscription_trade_cnt_max;
      this.subscriptionWallet.activeSubscriptionTradeCnt = data.active_subscription_trade_cnt;
      this.subscriptionWallet.activeSubscriptionStatus = data.active_subscription_status;
      this.subscriptionWallet.activeSubscriptionNextPymtAuthorized = data.active_subscription_next_pymt_authrorized;
      this.subscriptionWallet.activeSubscriptionTradeCntMin = data.active_subscription_trade_cnt_min;
      this.subscriptionWallet.subscriptionAutoTransfer = data.subscription_autotransfer;
      this.subscriptionWallet.submitCount = data.submit_cnt;
    }));
  }

  checkSubscriptionStatus() {
    const url = `${environment.checkSusbcription}?sessIDstr=${this.authService.authObj.sessIDstr}`;
    return this.http.get(url);
  }
}
