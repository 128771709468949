import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CoinAsset } from '../../models/common/coin-asset';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AppStoreService } from '../../services/app-store.service';
import { CoinListService } from '../../services/coin-list.service';
import { coinLibrary } from 'src/app/models/common/coinLibrary';
import { AssetDataService } from 'src/app/services/asset-data.service';

@Component({
  selector: 'app-coin-card',
  templateUrl: './coin-card.component.html',
  styleUrls: ['./coin-card.component.scss']
})
export class CoinCardComponent implements OnInit, OnDestroy {

  @Input() coin: CoinAsset;

  active = false;
  subscription: Subscription;
  subscription2: Subscription;

  constructor(
    private router: Router,
    public appStore: AppStoreService,
    private coinListService: CoinListService,
    public assetDataService: AssetDataService,
  ) { }

  ngOnInit(): void {
    if(window.innerWidth > 992) {
      this.initSelectedAssetCheck();
    }
  }

  initSelectedAssetCheck() {
    if(this.coin) {
      this.active = this.router.url.split('/')[2].split('_')[0].toUpperCase() === this.coin.id.toUpperCase() ? true : false;
      this.subscription = this.appStore.selectedAsset.subscribe((res: string) => {
        this.active = res.toUpperCase() === this.coin.id.toUpperCase() ? true : false;
      });
    }
  }

  onClick() {
    this.getCoinInfo(this.coin.id);
    // this.appStore.setSelectedAsset(this.coin.id); i think this is redundant - leave here for a bit before removing just in case
    const pair = this.coin.id.toUpperCase() + '_USDT';
    this.saveRoute(pair);
    this.router.navigate(['/dashboard', pair]);
  }

  getCoinInfo(id: string) {
    this.subscription2 = this.coinListService.getCoinInfo(id).subscribe((res: any) => {
      this.appStore.setSelectedAssetInfo(res);
    });
  }

  saveRoute(pair: string) {
    localStorage.setItem('empowerLastVisited', pair);
  }

  ngOnDestroy() {
    this.subscription?.unsubscribe();
    this.subscription2?.unsubscribe();
  }
}
