import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AnimatedBellComponent } from './animated-bell/animated-bell.component';
import { NightDayToggleComponent } from './night-day-toggle/night-day-toggle.component';
//components
import { ConfirmWithdrawalComponent } from './confirm-withdrawal/confirm-withdrawal.component';
import { UsdxWarningModalComponent } from './usdx-warning-modal/usdx-warning-modal.component';
import { SpaceElevatorComponent } from './space-elevator/space-elevator.component';

//pipes
import { TranslatePipe } from '../pipes/translate.pipe';
import { StatsWidgetComponent } from './stats-widget/stats-widget.component';
import { FilterComponent } from './filter/filter.component';
import { CirculationHistoryComponent } from './circulation-history/circulation-history.component';
import { ProductsListComponent } from './products-list/products-list.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardListComponent } from './circulation-history/card-list/card-list.component';
import { CoinCardComponent } from './coin-card/coin-card.component';
import { FavoriteStarComponent } from './favorite-star/favorite-star.component';
import { CirculationsOptionsComponent } from './circulations-options/circulations-options.component';
import { MyCirculationComponent } from './my-circulation/my-circulation.component';
import { LightweightChartComponent } from './lightweight-chart/lightweight-chart.component';
import { CustomModalComponent } from './custom-modal/custom-modal.component';
import { ChartInfoComponent } from './chart-info/chart-info.component';
import { CirculationDetailsComponent } from './circulation-history/circulation-details/circulation-details.component';
import { LoadingAnimationComponent } from './loading-animation/loading-animation.component';
import { CirculationConfirmationComponent } from './circulation-confirmation/circulation-confirmation.component';
import { LoginComponent } from './login/login.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { QrContractComponent } from './qr-contract/qr-contract.component';
import { LanguageSelectComponent } from './language-select/language-select.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { LoginButtonModalComponent } from './login-button-modal/login-button-modal.component';
import { InviteHistoryComponent } from './invite-history/invite-history.component';
import { ServiceCardComponent } from './service-card/service-card.component';
import { CirculationOrderBotCardComponent } from './circulation-order-bot-card/circulation-order-bot-card.component';
import { ReplacePipe } from '../pipes/replace.pipe';
import { OrderBotsInterfaceComponent } from './order-bots-interface/order-bots-interface.component';
import { OrderBotsListComponent } from './order-bots-list/order-bots-list.component';
import { EmpowerAndQrButtonComponent } from './empower-and-qr-button/empower-and-qr-button.component';
import { BondCardComponent } from './bond-card/bond-card.component';
import { CountdownTimerComponent } from './countdown-timer/countdown-timer.component';
import { BondsInterfaceComponent } from './bonds-interface/bonds-interface.component';
import { BondHistoryComponent } from './bond-history/bond-history.component';
import { TokenSwapComponent } from './token-swap/token-swap.component';
import { TokenSwapInputComponent } from './token-swap-input/token-swap-input.component';
import { AssetsDropDownComponent } from './assets-drop-down/assets-drop-down.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { AnimatedProgressBarComponent } from './animated-progress-bar/animated-progress-bar.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CaptchaComponent } from './captcha/captcha.component';
import { IonicModule } from '@ionic/angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CirculationInterfaceTextPipe } from '../pipes/circulation-interface.pipe';
import { RouterModule } from '@angular/router';
import { MetamaskNotificationComponent } from './metamask-notification/metamask-notification.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { UserInfoComponent } from './user-info/user-info.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { EmptyValuePipe } from '../pipes/empty-value.pipe';
import { CaptchaPopupComponent } from './captcha-popup/captcha-popup.component';
import { NotificationMenuComponent } from './notification-menu/notification-menu.component';
import { CustomToastComponent } from './custom-toast/custom-toast.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { InviteQrComponent } from './invite-qr/invite-qr.component';
import { CodedPipe } from '../pipes/coded.pipe';
import { TransferCreditModalComponent } from './transfer-credit-modal/transfer-credit-modal.component';
import { AnnouncementModalComponent } from './announcement-modal/announcement-modal.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
// import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { EmailLengthPipe } from '../pipes/email-length.pipe';
import { SwitchNetworkComponent } from './switch-network/switch-network.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CoinInfoPipe } from '../pipes/coin-info.pipe';
import { SubscriptionButtonComponent } from '../header/subscription-button/subscription-button.component';
import { BuySubscriptionCreditsComponent } from './buy-subscription-credits/buy-subscription-credits.component';
import { TruncateCenterPipe } from '../pipes/truncate-center.pipe';
import { GenericHistoryModalComponent } from './generic-history-modal/generic-history-modal.component';
import { WrongAddressModalComponent } from './wrong-address-modal/wrong-address-modal.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AssetInfoComponent } from './asset-info/asset-info.component';
import { ConvertCreidtsComponent } from './convert-creidts/convert-creidts.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { GeneralDescriptionComponent } from './general-description/general-description.component';
import { GenericConfirmationModalComponent } from './generic-confirmation-modal/generic-confirmation-modal.component';
import { WaveParticleAnimationComponent } from './wave-particle-animation/wave-particle-animation.component';
import { WaveParticleComponent } from './wave-particle/wave-particle.component';
import { AnimatedEmpowerLogoComponent } from './animated-empower-logo/animated-empower-logo.component';

@NgModule({
  declarations: [
    TranslatePipe,
    AnimatedBellComponent,
    StatsWidgetComponent,
    NightDayToggleComponent,
    FilterComponent,
    CirculationHistoryComponent,
    ProductsListComponent,
    SearchBarComponent,
    CardListComponent,
    CoinCardComponent,
    FavoriteStarComponent,
    CirculationsOptionsComponent,
    MyCirculationComponent,
    LightweightChartComponent,
    CustomModalComponent,
    CirculationDetailsComponent,
    ChartInfoComponent,
    LoadingAnimationComponent,
    CirculationConfirmationComponent,
    LoginComponent,
    VerifyEmailComponent,
    QrContractComponent,
    LanguageSelectComponent,
    LoginButtonModalComponent,
    InviteHistoryComponent,
    ServiceCardComponent,
    CirculationOrderBotCardComponent,
    ReplacePipe,
    OrderBotsInterfaceComponent,
    OrderBotsListComponent,
    EmpowerAndQrButtonComponent,
    BondCardComponent,
    CountdownTimerComponent,
    BondsInterfaceComponent,
    BondHistoryComponent,
    TokenSwapComponent,
    TokenSwapInputComponent,
    AssetsDropDownComponent,
    AnimatedProgressBarComponent,
    CaptchaComponent,
    CirculationInterfaceTextPipe,
    MetamaskNotificationComponent,
    UserInfoComponent,
    EmptyValuePipe,
    CaptchaPopupComponent,
    NotificationMenuComponent,
    CustomToastComponent,
    InviteQrComponent,
    CodedPipe,
    TransferCreditModalComponent,
    AnnouncementModalComponent,
    ChangePasswordComponent,
    ForgotPasswordComponent,
    LanguageSelectComponent,
    EmailLengthPipe,
    TruncateCenterPipe,
    // LanguageSelectorComponent,
    EmailLengthPipe,
    SwitchNetworkComponent,
    CoinInfoPipe,
    SubscriptionButtonComponent,
    BuySubscriptionCreditsComponent,
    GenericHistoryModalComponent,
    WrongAddressModalComponent,
    AssetInfoComponent,
    ConvertCreidtsComponent,
    GeneralDescriptionComponent,
    ConfirmWithdrawalComponent,
    GenericConfirmationModalComponent,
    UsdxWarningModalComponent,
    WaveParticleAnimationComponent,
    WaveParticleComponent,
    AnimatedEmpowerLogoComponent,
    SpaceElevatorComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatOptionModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatInputModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    IonicModule,
    DragDropModule,
    RouterModule,
    QRCodeModule,
    ClipboardModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    NgxSkeletonLoaderModule,
    TooltipModule,
  ],
  exports: [
    AnimatedBellComponent,
    TranslatePipe,
    ReplacePipe,
    StatsWidgetComponent,
    NightDayToggleComponent,
    FilterComponent,
    FormsModule,
    CirculationHistoryComponent,
    ProductsListComponent,
    SearchBarComponent,
    CardListComponent,
    CoinCardComponent,
    FavoriteStarComponent,
    CirculationsOptionsComponent,
    MyCirculationComponent,
    LightweightChartComponent,
    CustomModalComponent,
    CirculationDetailsComponent,
    ChartInfoComponent,
    LoadingAnimationComponent,
    CirculationConfirmationComponent,
    LoginComponent,
    VerifyEmailComponent,
    LanguageSelectComponent,
    LoginButtonModalComponent,
    InviteHistoryComponent,
    ServiceCardComponent,
    CirculationOrderBotCardComponent,
    OrderBotsInterfaceComponent,
    OrderBotsListComponent,
    EmpowerAndQrButtonComponent,
    BondCardComponent,
    CountdownTimerComponent,
    BondsInterfaceComponent,
    BondHistoryComponent,
    TokenSwapComponent,
    TokenSwapInputComponent,
    AssetsDropDownComponent,
    MatSlideToggleModule,
    MatInputModule,
    AnimatedProgressBarComponent,
    NgxCaptchaModule,
    ReactiveFormsModule,
    IonicModule,
    DragDropModule,
    CirculationInterfaceTextPipe,
    MetamaskNotificationComponent,
    QRCodeModule,
    ClipboardModule,
    MatSnackBarModule,
    EmptyValuePipe,
    CaptchaPopupComponent,
    NotificationMenuComponent,
    CustomToastComponent,
    CodedPipe,
    MatTooltipModule,
    TransferCreditModalComponent,
    AnnouncementModalComponent,
    EmailLengthPipe,
    SwitchNetworkComponent,
    MatProgressSpinnerModule,
    TruncateCenterPipe,
    CoinInfoPipe,
    SubscriptionButtonComponent,
    BuySubscriptionCreditsComponent,
    GenericHistoryModalComponent,
    WrongAddressModalComponent,
    NgxSkeletonLoaderModule,
    AssetInfoComponent,
    ConvertCreidtsComponent,
    TooltipModule,
    GeneralDescriptionComponent,
    ConfirmWithdrawalComponent,
    UsdxWarningModalComponent,
    WaveParticleAnimationComponent,
    WaveParticleComponent,
    AnimatedEmpowerLogoComponent,
    SpaceElevatorComponent
  ],
})
export class AppCommonModule {}
