import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { CoinAsset } from '../models/common/coin-asset';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  assets: any;
  establishedAddress;
  wallet = {
    // change back to null
    ethAddress: '0x407c5D6f7A462907deb329980c96a166CeEcb8AF',
    eth: 200,
    bnb: 10000,
    usdt: 1000,
  };
  bep20Wallet = {
    usdt: 10000,
    usdx: 10000,
    cnyx: 0,
    jpyx: 0,
    gbpx: 0,
  };
  credits: any = [
    {
      name: 'invite-earnings',
      available: 'bonusBalance',
      image: 'assets/images/credits-invite-v2-250px-min.png',
    },
    {
      name: 'subscription-credits',
      available: 'autopayBalance',
      image: 'assets/images/credits-empass-v2-250px-min.png',
    },
  ];

  constructor(private http: HttpClient, private authService: AuthService) {}

  addUserWallet(userWalletAddr, replaceOrNew) {
    // const url = environment.addUserWalletEndpoint;
    // const body = {
    //   sessIDstr: this.authService.authObj.sessIDstr,
    //   blockChain: 'eth',
    //   walletAddr: userWalletAddr,
    //   replaceOld: replaceOrNew,
    // };
    // const config = {
    //   headers: new HttpHeaders().set('Content-Type', 'application/json'),
    // };
    // return this.http.post(url, body, config);
  }

  getWalletInfo() {
    // const url = `${environment.addUserWalletEndpoint}?sessIDstr=${this.authService.authObj.sessIDstr}`;
    // return this.http.get(url);
  }

  getETHWalletBalance(): Observable<any> {
    return of([
      {
        name: 'Ethereum',
        id: 'ethereum',
        balance: '2.24512',
      },
      {
        name: 'USDT',
        id: 'tether',
        balance: '21234.21',
      },
    ]);
  }

  sendSubscriptionCredits(targetUser, creditsToGive) {
    const url = environment.sendCreditsEndpoint;
    const body = {
      sessIDstr: this.authService.authObj.sessIDstr,
      targetUser,
      creditsToGive
    };
    const config = {
      headers: new HttpHeaders().set('Content-Type', 'application/json'),
    };
    return this.http.post(url, JSON.stringify(body), config);
  }

  convertInviteEarnings(amt) {
    // const url = environment.rewardsHistoryEndpoint;
    // const body = {
    //   sessIDstr: this.authService.authObj.sessIDstr,
    //   amt,
    // };
    // const config = {
    //   headers: new HttpHeaders().set('Content-Type', 'application/json'),
    // };
    // return this.http.post(url, JSON.stringify(body), config);
  }

}
