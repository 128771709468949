/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { CoinAsset } from '../models/common/coin-asset';
import { coinLibrary } from '../models/common/coinLibrary';
import { circulationContract, usdtAddress } from '../constants/addresses';

@Injectable({
  providedIn: 'root',
})
export class CoinListService {
  data = [
    // FOR TESTING PURPOSES
    // {
    //   id: 'usdx2',
    //   name: 'USDx',
    //   symbol: 'USDx',
    //   coinAddress: usdxAddress,
    //   sendContractAddress: circulationContract.usdxNew.address,
    //   issue: circulationContract.usdxNew.issue,
    //   image: 'assets/images/coin-logo/USDx-new.png',
    //   activity24h: 1241256,
    //   usdxConversion: 1,
    //   allTimeValue: 51512390,
    //   allTimeAsset: 51512390,
    //   live: true,
    //   new: true,
    // },
    // END
    {
      id: 'usdt',
      name: 'USDT',
      symbol: 'USDT',
      coinAddress: usdtAddress,
      sendContractAddress: circulationContract.usdxOld.address,
      issue: circulationContract.usdxOld.issue,
      image: 'assets/images/coin-logo/USDT.png',
      activity24h: 1241256,
      usdxConversion: 1,
      allTimeValue: 51512390,
      allTimeAsset: 51512390,
      info: 'usdt-coin-info',
      live: true,
    },
    // {
    //   id: 'cnyx',
    //   name: 'CNYx',
    //   symbol: 'CNYx',
    //   coinAddress: '',
    //   sendContractAddress: '',
    //   image: 'assets/images/coin-logo/CNYx.png',
    //   activity24h: null,
    //   usdxConversion: null,
    //   allTimeValue: null,
    //   allTimeAsset: null,
    //   live: false
    // },
    // {
    //   id: 'jpyx',
    //   name: 'JPYx',
    //   symbol: 'JPYx',
    //   coinAddress: '',
    //sendContractAddress: '',
    //   image: 'assets/images/coin-logo/JPYx.png',
    //   activity24h: null,
    //   usdxConversion: null,
    //   allTimeValue: null,
    //   allTimeAsset: null,
    //   live: false
    // },
    // {
    //   id: 'gbpx',
    //   name: 'GBPx',
    //   symbol: 'GBPx',
    //   coinAddress: '',
    //sendContractAddress: '',
    //   image: 'assets/images/coin-logo/GBPx.png',
    //   activity24h: null,
    //   usdxConversion: null,
    //   allTimeValue: null,
    //   allTimeAsset: null,
    //   live: false
    // },
    {
      id: '',
      name: '',
      symbol: '',
      coinAddress: '',
      sendContractAddress: '',
      image: '',
      activity24h: null,
      usdxConversion: null,
      allTimeValue: null,
      allTimeAsset: null,
      live: false,
    },
    {
      id: '',
      name: '',
      symbol: '',
      coinAddress: '',
      sendContractAddress: '',
      image: '',
      activity24h: null,
      usdxConversion: null,
      allTimeValue: null,
      allTimeAsset: null,
      live: false,
    },
    {
      id: '',
      name: '',
      symbol: '',
      coinAddress: '',
      sendContractAddress: '',
      image: '',
      activity24h: null,
      usdxConversion: null,
      allTimeValue: null,
      allTimeAsset: null,
      live: false,
    },
    {
      id: '',
      name: '',
      symbol: '',
      coinAddress: '',
      sendContractAddress: '',
      image: '',
      activity24h: null,
      usdxConversion: null,
      allTimeValue: null,
      allTimeAsset: null,
      live: false,
    },
    {
      id: '',
      name: '',
      symbol: '',
      coinAddress: '',
      sendContractAddress: '',
      image: '',
      activity24h: null,
      usdxConversion: null,
      allTimeValue: null,
      allTimeAsset: null,
      live: false,
    },
    {
      id: '',
      name: '',
      symbol: '',
      coinAddress: '',
      sendContractAddress: '',
      image: '',
      activity24h: null,
      usdxConversion: null,
      allTimeValue: null,
      allTimeAsset: null,
      live: false,
    },
    {
      id: '',
      name: '',
      symbol: '',
      coinAddress: '',
      sendContractAddress: '',
      image: '',
      activity24h: null,
      usdxConversion: null,
      allTimeValue: null,
      allTimeAsset: null,
      live: false,
    },
    {
      id: '',
      name: '',
      symbol: '',
      coinAddress: '',
      sendContractAddress: '',
      image: '',
      activity24h: null,
      usdxConversion: null,
      allTimeValue: null,
      allTimeAsset: null,
      live: false,
    },
    {
      id: '',
      name: '',
      symbol: '',
      coinAddress: '',
      sendContractAddress: '',
      image: '',
      activity24h: null,
      usdxConversion: null,
      allTimeValue: null,
      allTimeAsset: null,
      live: false,
    },
  ];

  constructor(private http: HttpClient) {}

  getCoinsList() {
    return this.data;
  }

  getCoinInfo(id: string) {
    const coin = this.data.filter(
      (x: any) => x.id.toLowerCase() === id.toLowerCase()
    )[0];
    return of(coin);
  }

  getCandleData(id: string) {
    return of(coinLibrary[id.toLowerCase()].candleData).pipe(delay(500));
  }
}
