import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenSwapService {
  loadingTransaction = false;

  stepsWalletList = [
    {
      name: 'Ethereum',
      symbol: 'ETH',
      image: 'assets/images/coin-logo/ETH.png',
    }
  ];

  fullInputSwapWalletList = [
    {
      name: 'Ethereum',
      symbol: 'ETH',
      image: 'assets/images/coin-logo/ETH.png',
    }
  ];

  ethNetworkOutputWalletList = [
    {
      name: 'Tether',
      symbol: 'USDT',
      image: 'assets/images/coin-logo/USDT.png',
    },
  ];

  bscNetworkOutputWalletList = [
    {
      name: 'Ethereum',
      symbol: 'ETH',
      image: 'assets/images/coin-logo/ETH.png',
    },
    {
      name: 'Tether',
      symbol: 'USDT',
      image: 'assets/images/coin-logo/USDT.png',
    },
  ];

  constructor(private http: HttpClient) {}

  getEstimate(fromToken, toToken, amount) {
    const url = 'https://empower.wang/swap/api/getPrice';
    const headers = new HttpHeaders().set(
      'content-type',
      'application/x-www-form-urlencoded'
    );
    const body = new HttpParams().set(
      'inputStr',
      JSON.stringify({
        swapFromToken: fromToken,
        swapToToken: toToken,
        swapFromAmount: amount,
      })
    );
    // return this.http.post(url, body, { headers });
    return of({
      data: {
        swapToAmount: 3100
      }
    })
  }
}
