import { SubscriptionModalComponent } from './../../header/subscription-modal/subscription-modal.component';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserInfoComponent } from '../user-info/user-info.component';
import { AuthService } from '../../services/auth.service';
import { WalletService } from '../../services/wallet.service';
import { Router } from '@angular/router';
import { staggerListAnimation2, fade2 } from 'src/app/animations/animations';
import { ModalProperties } from 'src/app/constants/set-modal-properties';
import { ForTranslateService } from 'src/app/services/for-translate.service';

@Component({
  selector: 'app-notification-menu',
  templateUrl: './notification-menu.component.html',
  styleUrls: ['./notification-menu.component.scss'],
  animations: [
    staggerListAnimation2,
    fade2
  ]
})
export class NotificationMenuComponent implements OnInit {
  @Input() show: boolean;
  @Output() loggingOut = new EventEmitter();

  languageSelectOpen = false;

  titles = [
    // 'account',
    'dashboard',
    'subscription',
    'get-crypto',
    'wallet',
    'settings',
    // 'Title 4',
    // 'Title 5',
    // 'Title 6',
    // 'title 7',
    // 'title 8',
  ];

  constructor(
    public dialog: MatDialog,
    private authService: AuthService,
    private walletService: WalletService,
    public router: Router,
    public translateService: ForTranslateService
    ) {}

  ngOnInit() {}

  navigate(nav) {
    if (nav === 'account') {
      this.openUserInfo();
    } else if (nav === 'logout'){
      this.logout();
      this.loggingOut.emit(false);
    } else if (nav === 'subscription') {
      if (ModalProperties.properties.screenWidth > 750) {
        ModalProperties.properties.width = '850px';
        ModalProperties.properties.maxWidth = '90vw';
      }
      ModalProperties.properties.panelClass = ['modal-background', 'small-screen'];
      this.dialog.open(SubscriptionModalComponent, ModalProperties.properties);
      ModalProperties.resetProperties();
    } else {
      const link = '/' + nav;
      this.router.navigate([link]);
    }
    this.loggingOut.emit(false);
  }

  openUserInfo() {
    this.dialog.open(UserInfoComponent, {
      width: '40vw',
      panelClass: 'modal-background',
    });
  }

  openLanguageSelect() {
    this.languageSelectOpen = true;
  }

  setLanguageSelect() {
    this.languageSelectOpen = false;
  }

  logout() {
    this.authService.logout();
  }

}
